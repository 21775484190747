// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-logo{
  padding:"30px";
  padding-bottom:"30px";
}
.img-logo {
  width: 100px;
  height: auto;
}

.logo_nav {
  margin-top: 3rem;
}

a:link{
  text-decoration: none;
  position: relative;
  color: #4c4c4c;
}

a:visited {
  color: #4c4c4c;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  background-color: transparent;
}

a:active {
  color: #FE9E0D !important;
  background-color: transparent;
}
/* 
a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #FE9E0D;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after { 
  width: 100%; 
  left: 0; 
} */`, "",{"version":3,"sources":["webpack://./src/Assets/Css/logo_definition.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;;;;;;;;;;;;;;;;GAgBG","sourcesContent":[".section-logo{\r\n  padding:\"30px\";\r\n  padding-bottom:\"30px\";\r\n}\r\n.img-logo {\r\n  width: 100px;\r\n  height: auto;\r\n}\r\n\r\n.logo_nav {\r\n  margin-top: 3rem;\r\n}\r\n\r\na:link{\r\n  text-decoration: none;\r\n  position: relative;\r\n  color: #4c4c4c;\r\n}\r\n\r\na:visited {\r\n  color: #4c4c4c;\r\n  background-color: transparent;\r\n  text-decoration: none;\r\n}\r\n\r\na:hover {\r\n  background-color: transparent;\r\n}\r\n\r\na:active {\r\n  color: #FE9E0D !important;\r\n  background-color: transparent;\r\n}\r\n/* \r\na:after {    \r\n  background: none repeat scroll 0 0 transparent;\r\n  bottom: 0;\r\n  content: \"\";\r\n  display: block;\r\n  height: 2px;\r\n  left: 50%;\r\n  position: absolute;\r\n  background: #FE9E0D;\r\n  transition: width 0.3s ease 0s, left 0.3s ease 0s;\r\n  width: 0;\r\n}\r\na:hover:after { \r\n  width: 100%; \r\n  left: 0; \r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
