// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-showcase {
  margin-top: 50px !important;
  margin-bottom: 300px !important;
}


.icons-footer li {
  transition: all 0.3s linear;
}

.icons-footer li:hover {
  transform: scale(1.5);
}

.youtube:hover {
  color: #FF0000 !important;
}

.spotify:hover {
  color: #1ED760 !important;
}

.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  /* margin-right: 1.25rem; */
  margin-top: -55px;
  margin-left: -30px;
}

.icons-footer{
  font-size: 24px;
  margin-left: -15px;
}

@media (max-width:991px) {
  .radio-showcase {
    margin-bottom: 700px !important;
  }
}

@media (max-width: 800px) {
  .icons-footer {
    /* text-align: center;
    justify-content: center;
    display: flex;
    align-items: center; */
    font-size: 24px;
    /* margin-left: -15px; */
  }
  
}

@media (max-width:768px) {
  .radio-showcase {
    margin-bottom: 1000px !important;
  }
}

@media (max-width:550px) {
  .radio-showcase {
    margin-bottom: 1200px !important;
  }
}

@media (max-width:443px) {
  .radio-showcase {
    margin-bottom: 1400px !important;
  }
}

`, "",{"version":3,"sources":["webpack://./src/Pages/radioPage/SinglePageRadio/singlePageRadio.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,+BAA+B;AACjC;;;AAGA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE;;;0BAGsB;IACtB,eAAe;IACf,wBAAwB;EAC1B;;AAEF;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF","sourcesContent":[".radio-showcase {\n  margin-top: 50px !important;\n  margin-bottom: 300px !important;\n}\n\n\n.icons-footer li {\n  transition: all 0.3s linear;\n}\n\n.icons-footer li:hover {\n  transform: scale(1.5);\n}\n\n.youtube:hover {\n  color: #FF0000 !important;\n}\n\n.spotify:hover {\n  color: #1ED760 !important;\n}\n\n.footer-icons {\n  margin-top: 2.5rem;\n}\n.footer-icons svg {\n  font-size: 1.5rem;\n  /* margin-right: 1.25rem; */\n  margin-top: -55px;\n  margin-left: -30px;\n}\n\n.icons-footer{\n  font-size: 24px;\n  margin-left: -15px;\n}\n\n@media (max-width:991px) {\n  .radio-showcase {\n    margin-bottom: 700px !important;\n  }\n}\n\n@media (max-width: 800px) {\n  .icons-footer {\n    /* text-align: center;\n    justify-content: center;\n    display: flex;\n    align-items: center; */\n    font-size: 24px;\n    /* margin-left: -15px; */\n  }\n  \n}\n\n@media (max-width:768px) {\n  .radio-showcase {\n    margin-bottom: 1000px !important;\n  }\n}\n\n@media (max-width:550px) {\n  .radio-showcase {\n    margin-bottom: 1200px !important;\n  }\n}\n\n@media (max-width:443px) {\n  .radio-showcase {\n    margin-bottom: 1400px !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
