// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 646px) {
  .ads-topnews {
    margin-top: 80px ;
  }
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/ads.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media (max-width: 646px) {\n  .ads-topnews {\n    margin-top: 80px ;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
