// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .interview-page-container {
  margin: 100px 0px;
} */



/* .background {
  background: linear-gradient(#e66465, #9198e5);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.scrollText {
  font-size: 1.5rem;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  height: 10rem;
  width: 25%;
  text-align: center;
  border-radius: 10px;
}

.parallax {
  margin-right: 15%;
}

.sticky {
  margin-left: 15%;
  background-color: #ff6d6d;
}

.blue {
  background-color: #5b65ad;
  color: white;
}

.purple {
  background-color: #9d65d0;
} */

/* .text-interview {
  margin-top: 50px !important;
}


@media (max-width: 800px) {
  .card {
    width: 40%;
  }

  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 1.5rem;
  }

  .about-section-text-container{
    margin-top: 150px !important;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/Pages/interviewPage/interviewPage.css"],"names":[],"mappings":"AAAA;;GAEG;;;;AAIH;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA0CG;;AAEH;;;;;;;;;;;;;;;;;;;;;GAqBG","sourcesContent":["/* .interview-page-container {\r\n  margin: 100px 0px;\r\n} */\r\n\r\n\r\n\r\n/* .background {\r\n  background: linear-gradient(#e66465, #9198e5);\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  left: 0;\r\n  width: 100vw;\r\n  height: 100vh;\r\n}\r\n\r\n.scrollText {\r\n  font-size: 1.5rem;\r\n}\r\n\r\n.card {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 1.25rem;\r\n  height: 10rem;\r\n  width: 25%;\r\n  text-align: center;\r\n  border-radius: 10px;\r\n}\r\n\r\n.parallax {\r\n  margin-right: 15%;\r\n}\r\n\r\n.sticky {\r\n  margin-left: 15%;\r\n  background-color: #ff6d6d;\r\n}\r\n\r\n.blue {\r\n  background-color: #5b65ad;\r\n  color: white;\r\n}\r\n\r\n.purple {\r\n  background-color: #9d65d0;\r\n} */\r\n\r\n/* .text-interview {\r\n  margin-top: 50px !important;\r\n}\r\n\r\n\r\n@media (max-width: 800px) {\r\n  .card {\r\n    width: 40%;\r\n  }\r\n\r\n  .parallax {\r\n    margin-right: 1.5rem;\r\n  }\r\n\r\n  .sticky {\r\n    margin-left: 1.5rem;\r\n  }\r\n\r\n  .about-section-text-container{\r\n    margin-top: 150px !important;\r\n  }\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
